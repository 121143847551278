<template>
    <div>
        <div class="container-fluid pl-5 pr-5 content-container ">

            <!--<add :path="url.add" v-on:added="newRecordAdded"></add>-->

            <!--<edit :path="url.update" :customer="customer" v-on:updated="recordUpdated"></edit>-->
            <!--<detail :customer="customer"></detail>-->
            <!--<div class="row">-->
            <!--<div class="col-12 bg-light shadow p-3 mb-3 ">-->
            <!--<p class="heading">Filters</p>-->
            <!--<hr/>-->
            <!--<keyword-filter :url="url.filter" :search_types="{1:'First Name',2:'Email','3':'Phone'}"-->
            <!--v-on:filtered="setFilteredData" v-on:clear="fetchData"></keyword-filter>-->
            <!--</div>-->
            <!--</div>-->
            <axios :url="url" :request="request" :data="data" :trigger="trigger" v-on:success="handle_request"
                   v-on:error="setErrors($event.response)"
                   v-on:request="trigger=false;"></axios>

            <div class="row">

                <div class="col-sm-12 bg-light shadow p-3 text-left " v-if="customer!=null">


                    <p class="heading">Customer detail</p>
                    <hr>

                    <div class="table-responsive mt-2">
                        <table class="table table-bordered table-sm">
                            <tr>
                                <td>Name</td><td>{{customer.company}}</td>

                            </tr>
                            <tr>
                                <td>Phone</td>
                                <td>{{customer.phone}}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>{{customer.address}}</td>
                            </tr>
                            <tr>
                                <td>Payment Interval</td>
                                <td>{{customer.payment_interval_name}}</td>
                            </tr>
                            <tr>
                                <td>Max payment limit</td>
                                <td>{{customer.max_payment_limit|currency}}</td>
                            </tr>
                            <tr>
                                <td>Account Type</td>
                                <td>{{customer.account_type_name}}</td>
                            </tr>
                            <tr>
                                <td>TI Number</td>
                                <td>{{customer.ti_number}}</td>
                            </tr>
                            <tr>
                                <td>Exempt Number</td>
                                <td>{{customer.exempt_number}}</td>
                            </tr>

                            <tr>
                                <td>Contact Name</td>
                                <td>{{customer.contact_name}}</td>
                            </tr>
                            <tr>
                                <td>Contact Email</td>
                                <td>{{customer.user.email}}</td>
                            </tr>

                        </table>
                    </div>
                    <p class="heading">Shipping address</p>

                    <div class="table-responsive ">
                        <table class="table table-bordered table-sm" v-if="customer.user.delivery_address[0]!=null">
                            <tr>
                                <td>Name</td><td>{{customer.user.delivery_address[0].full_name}}</td>
                            </tr>
                            <tr>
                                <td>Phone</td>
                                <td>{{customer.user.delivery_address[0].phone}}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{{customer.user.delivery_address[0].email_address}}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>{{customer.user.delivery_address[0].full_address}}</td>
                            </tr>
                        </table>
                        <table v-else="">
                            <tr>
                                <td colspan="2">Shipping address is not found</td>
                            </tr>
                        </table>
                    </div>

                    <p class="heading">Billing address</p>

                    <div class="table-responsive ">
                        <table class="table table-bordered table-sm" v-if="customer.user.delivery_address[0]!=null">
                            <tr>
                                <td>Name</td><td>{{customer.user.billing_address[0].full_name}}</td>
                            </tr>
                            <tr>
                                <td>Phone</td>
                                <td>{{customer.user.billing_address[0].phone}}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{{customer.user.billing_address[0].email_address}}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>{{customer.user.billing_address[0].full_address}}</td>
                            </tr>
                        </table>
                        <table v-else="">
                            <tr>
                                <td colspan="2">Billing address is not saved</td>
                            </tr>
                        </table>
                    </div>

                </div>

                <div class="col-sm-12 bg-light shadow p-3 text-left ">
                    <div class="text-center">
                        <pulse-loader :loading="trigger"></pulse-loader>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import forms from '@/forms';

    import {Paths} from '../../paths'
    import axios from  '@/views/actions/Axios';
    export default{
        extends: forms,
        components:{axios},
        props: ['user_id'],
        data(){
            return {
                url:'',
                request:'post',
                data:{user_id:0},
                trigger:false,
                customer:null,

            }
        }, methods: {
            handle_request(data){
                this.customer=data.data;
            }
        },
        mounted(){

            this.url=Paths.customer.detail;
            this.data.user_id=this.user_id;
            this.trigger=true;

        }
    }
</script>